/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI Components
import {
  Grid,
  Button,
  FormControl,
  MenuItem,
  TextField,
  Select,
  InputLabel,
  Typography,
} from '@material-ui/core';

// local components
import {
  VerdictAPIsSingleton as APIVerdict,
  ApprovalsAPIsSingleton as APIApproval,
} from '../../modules/api';
import { useUserState } from '../../context/UserContext';

import filterSelectOptions from './helpers/functions';
// styles
import useStyles from './styles';

const GroupSelectVerdict = ({
  updateJob,
  jobId,
  scraperVerdict,
  expertVerdict,
}) => {
  const classes = useStyles();
  const { accesToken } = useUserState();
  // local
  const [isLoading, setIsLoading] = useState(false);
  const [typeAction, setTypeAction] = useState('');
  const [commentVerdict, setCommentVerdict] = useState('');
  const {
    confirmAction,
    adjustAction,
  } = filterSelectOptions(expertVerdict?.outcome || scraperVerdict);

  //  handles
  const handleChangeType = (event) => {
    setTypeAction(event.target.value);
  };

  const handleChangeComment = (event) => {
    setCommentVerdict(event.target.value);
  };
  //  Api
  const handleSubmission = () => {
    setIsLoading(true);
    if (!typeAction) {
      setIsLoading(false);
      return;
    }
    if (typeAction === 'SSOT') {
      APIApproval.postApproval(accesToken, jobId, expertVerdict.outcome)
        .then(() => {
          setIsLoading(false);
          updateJob();
        })
        .catch((e) => {
          setIsLoading(false);
          if (e && e.message && e.message === 'LOGOUT') {
            console.log(e);
          }
        });
    } else {
      APIVerdict.postVerdic(accesToken, jobId, typeAction, commentVerdict)
        .then(() => {
          setIsLoading(false);
          updateJob();
        })
        .catch((e) => {
          setIsLoading(false);
          if (e && e.message && e.message === 'LOGOUT') {
            console.log(e);
          }
        });
    }
  };
  return (
    <Grid container className={classes.formControlComment}>
      <Typography variant="caption">Insert Verdict</Typography>
      <FormControl variant="outlined" className={classes.formControlComment}>
        <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Type"
          fullwidth="true"
          onChange={handleChangeType}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={confirmAction.value}>{confirmAction.text}</MenuItem>
          <MenuItem value={adjustAction.value}>{adjustAction.text}</MenuItem>
          <MenuItem value="SSOT">Send to SSOT</MenuItem>
        </Select>
        <TextField
          id="type"
          margin="normal"
          type="text"
          fullwidth="true"
          variant="outlined"
          label="Comment"
          onChange={handleChangeComment}
        />
        <Button
          disabled={isLoading}
          size="small"
          variant="outlined"
          onClick={() => handleSubmission()}
          className={classes.verdictBtn}
        >
          Send
        </Button>
      </FormControl>
    </Grid>
  );
};

GroupSelectVerdict.propTypes = {
  scraperVerdict: PropTypes.string.isRequired,
  expertVerdict: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  updateJob: PropTypes.func.isRequired,
};
export default GroupSelectVerdict;
