import { createTheme } from '@material-ui/core';
import defaultTheme from './default';

const overrides = {
  typography: {
    fontFamily: "'Inter', Helvetica, Arial, sans-serif;",
    h1: {
      fontSize: '3rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.64rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.285rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.142rem',
      fontWeight: 600,
    },
    body1: {
      fontWeight: 400,
      fontSize: '.87rem',
    },
    body2: {
      fontWeight: 500,
      fontSize: '1rem',
    },
    caption: {
      fontWeight: 400,
      fontSize: '.75rem',
      color: '#334155',
    },
    button: {},
    subtitle1: {
      fontWeight: 400,
      fontSize: '1.25rem',
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: '1.25rem',
    },
    overline: {
      fontWeight: 400,
      fontSize: '.87rem',
      borderBottom: '1px solid rgba(15, 23, 42, 1)',
    },
  },
  // This actually override (differently from default/index)
  overrides: {
    MUIDataTable: {
      paper: {
        borderRadius: 10,
        boxShadow: 'none',
        width: '100%',
        border: '1px solid #F1F5F9',
      },
    },
    MUIDataTableSearch: {
      searchIcon: {
        display: 'none',
      },
    },
    MUIDataTableHeadCell: {
      root: {
        height: '.5rem',
      },
      data: {
        color: '#475569',
        textDecoration: 'none',
        borderBottom: 'none !important',
        textTransform: 'upperCase',
        fontSize: '.75rem',
      },
      toolButton: {
        fontWeight: 'bold',
        color: '#222222',
        textDecoration: 'none',
        fontSize: '1.01em',
        margin: 0,
        padding: 0,
        marginRight: 0,
        marginLeft: 0,
      },
      sortLabelRoot: {
        height: '100%',
      },
      contentWrapper: {
        justifyContent: 'center',
      },
    },
    MUIDataTableFilter: {
      root: {
        backgroundColor: '#0080800d',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#64748B',
      },
    },
    MuiToolbar: {
      root: {
        heigth: '1rem',
        paddingTop: '2rem',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '.75rem',
      },
      head: {
        fontWeight: 'bold',
        color: '#222222',
      },
    },
    MuiTabs: {
      flexContainer: {
        justifyContent: 'space-between',
      },
    },
    MuiTab: {
      root: {
        fontSize: '1.142rem',
        padding: 0,
        textTransform: 'none',
        minHeight: 0,
        minWidth: 'none',
        justifyContent: 'center',
      },
    },
  },
};

export default {
  default: createTheme({ ...defaultTheme, ...overrides }),
};
