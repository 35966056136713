import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({

  container: {
    height: '100%',
    width: '100%',
  },
  successBtn: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    border: `1px solid ${theme.palette.success.main}`,
    boxShadow: theme.customShadows.sm,
    borderRadius: 5,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.success.light,
    },
  },
  errorBtn: {
    color: theme.palette.error.dark,
    backgroundColor: theme.palette.error.light,
    border: '1px solid #d412124a',
    boxShadow: theme.customShadows.sm,
    borderRadius: 5,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.error.light,
    },
  },
  sootBtn: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: theme.customShadows.sm,
    borderRadius: 5,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.light,
    },
  },

  formControlComment: {
    display: 'flex',
    height: '100%',
  },

  verdictBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    marginTop: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: 'white',
    },
  },
}));
