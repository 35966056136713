/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// material UI
import { Grid, Typography, IconButton } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import TuneIcon from '@material-ui/icons/Tune';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import BlockIcon from '@material-ui/icons/Block';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import RepeatRoundedIcon from '@material-ui/icons/RepeatRounded';
import QueueRoundedIcon from '@material-ui/icons/QueueRounded';
import SyncIcon from '@material-ui/icons/Sync';
// local components
import Tooltip from '../../../components/common/tooltip/Tooltip';
import Link from '../../../components/common/link/Link';
import Chip from '../../../components/common/chip/Chip';
import settings from './utils/tableSettings';
// styles
import useStyles from './styles';

const components = {
  icons: {
    FilterIcon: TuneIcon,
  },
};

export default function TableTerms({
  data, removeThisJob, update,
}) {
  const classes = useStyles();
  const allCol = [];
  const filteredStatus = (status) => {
    switch (status) {
      case 'completed':
        return {
          icon: <CheckCircleOutlineRoundedIcon className={classes.checkIcon} />,
          text: 'Completed',
          tooltip: 'Completed: The scraping process is completed, you can now look at the results',
        };
      case 'error':
        return {
          icon: <BlockIcon className={classes.blockIcon} />,
          text: 'Interrupted',
          tooltip: `Interrupted: Something happened before the process could have been completed. 
            The process has been stopped`,
        };
      case 'running':
        return {
          icon: <RepeatRoundedIcon className={classes.running} />,
          text: 'Running',
          tooltip: 'Running: Scraping the brand\'s pages',
        };
      case 'queued':
        return {
          icon: <QueueRoundedIcon className={classes.queue} />,
          text: 'Queued',
          tooltip: 'Queued: before starting the scraping process is waiting for other processes to terminate',
        };
      default:
        return '';
    }
  };

  data.forEach((row) => {
    const {
      _id, brand, status, scraperVerdict, expertVerdict, createdAt,
    } = row;
    allCol.push([
      _id,
      brand,
      status,
      scraperVerdict,
      expertVerdict?.outcome || 'No verdict',
      createdAt,
    ]);
  });

  const { options } = settings(
    <Tooltip text="Refresh results">
      <IconButton onClick={update()}><SyncIcon /></IconButton>
    </Tooltip>,
  );
  return (
    <MUIDataTable
      className={classes}
      data={allCol}
      {...{ components }}
      columns={[
        {
          name: 'id',
          label: 'ID',
          options: {
            searchable: false,
            filter: false,
            display: 'excluded',
          },
        },
        {
          name: 'name',
          label: 'Name',
          options: {
            filter: false,
            searchable: true,
            customBodyRender: (value, tableMeta) => {
              const { rowData } = tableMeta;
              const id = rowData[0];
              const status = rowData[2];
              return (
                status === 'completed' ? (
                  <Link
                    to={`/scraper-tool/brand/${id}`}
                    text={value}
                    style={{ textTransform: 'uppercase' }}
                  />
                ) : <Typography variant="caption">{value}</Typography>
              );
            },
          },
        },
        {
          name: 'scraping status',
          label: 'Scraping Status',
          options: {
            filter: true,
            searchable: false,
            customBodyRender: (value) => (
              <Grid item align="center">
                <Tooltip text={filteredStatus(value).tooltip}>
                  {filteredStatus(value).icon}
                </Tooltip>
              </Grid>
            ),
          },
        },
        {
          name: 'scraper verdict',
          label: 'Scraper Verdict',
          options: {
            filter: true,
            searchable: false,
            customBodyRender: (value) => (
              <Grid item align="center">
                <Chip
                  label={value}
                />
              </Grid>
            ),
          },
        },
        {
          name: 'expert verdict',
          label: 'Expert Verdict',
          options: {
            filter: true,
            searchable: false,
            customBodyRender: (value) => (
              <Grid item align="center">
                <Chip
                  label={value}
                />
              </Grid>
            ),
          },
        },
        {
          name: 'date',
          label: 'Date',
          options: {
            filter: true,
            searchable: false,
            customBodyRender: (value, tableMeta) => {
              const { rowData } = tableMeta;
              const status = rowData[2];
              return (
                <Grid container justifyContent="space-evenly" alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body1">{moment(value).format('DD MMM YYYY')}</Typography>
                  </Grid>
                  <Grid item xs={4} align="right">
                    {status === 'error' && (
                      <Tooltip text="Delete this result">
                        <DeleteOutlineRoundedIcon
                          className={classes.deleteBnt}
                          onClick={() => removeThisJob(rowData[0])}
                        />
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              );
            },
          },
        },
      ]}
      options={options}
    />
  );
}
TableTerms.propTypes = {
  data: PropTypes.shape([]).isRequired,
  update: PropTypes.func.isRequired,
  removeThisJob: PropTypes.func.isRequired,
};
