import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({

  container: {
    margin: 0,
  },
  input: {
    color: theme.palette.primary.main,
    padding: 0,
    margin: 0,
  },
  textField: {
    height: 44,
    margin: 0,
  },
}));
