import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import moment from 'moment';
// Material Ui
import {
  Grid, Button, TextField, Fade, Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
// Local components
import TextInput from '../../components/common/textInput/TextInput';
import TableJobStatusRefactor from './tables/TableJobsStatusRefactor';
import { useUserDispatch, signOut, useUserState } from '../../context/UserContext';
import { JobsAPIsSingleton as APIJobs, BrandsAPIsSingleton as APIBrands } from '../../modules/api';
// styles
import useStyles from './styles';

// error boundaries
// eslint-disable-next-line no-useless-escape
const urlFormatExpression = new RegExp('([a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})');
const schema = yup.object().shape({
  brandValue: yup.string().required('Please include a brand name'),
  urlValue: yup.lazy(
    (value) => {
      if (!value) {
        return yup.string().required('Please include a website URL');
      }
      return yup.string().matches(urlFormatExpression, { message: 'Please include a valid URL, eg brandname.com' }).required('Please include a website URL');
    },
  ),
// eslint-disable-next-line react/prop-types
}); export default function Scraper({ history }) {
  const classes = useStyles();
  const { accessToken } = useUserState();
  const userDispatch = useUserDispatch();

  // local
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Please include a valid email or password');
  const [update, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [brandValue, setBrandValue] = useState('');
  const [urlValue, setUrlValue] = useState('');
  const [resetAutoComplete, setResetAutoComplete] = useState('test');

  const isAlreadyScraped = jobs.filter((job) => job.brand === brandValue);

  const [jobAlreadyScraped, dateScraped] = [
    isAlreadyScraped[isAlreadyScraped.length - 1]?.brand,
    isAlreadyScraped[isAlreadyScraped.length - 1]?.createdAt,
  ];
  //  handles

  const handleOnChangeBranName = (value) => {
    setError(false);
    if (value) {
      setBrandValue(value.name);
      setUrlValue(value.url);
    }
  };

  const handleStartScraper = () => async () => {
    await schema
      .validate({
        brandValue,
        urlValue,
      })
      .then(() => {
        const external = brandData
          .find((brand) => brand.name.toLowerCase() === brandValue.toLowerCase());
        setIsLoading(true);
        const data = {
          brand: brandValue,
          url: urlValue,
          external: !external === undefined,
        };
        APIJobs.startJob(accessToken, data)
          .then(() => {
            setIsLoading(false);
            setBrandValue('');
            setUrlValue('');
            handleOnChangeBranName({
              name: '',
              url: '',
            });
            setResetAutoComplete(Math.random());
            setUpdate(!update);
          })
          .catch((e) => {
            setIsLoading(false);
            if (e && e.message && e.message === 'LOGOUT') {
              // eslint-disable-next-line no-console
              console.log(e);
            }
          });
      }).catch((e) => {
        setBrandValue('');
        setUrlValue('');
        setError(true);
        setErrorMessage(e.message);
      });
  };

  const handleRemoveJob = (id) => {
    APIJobs.deleteJob(accessToken, id)
      .then(() => {
        setUpdate(!update);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e && e.message && e.message === 'LOGOUT') {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      });
  };
  useEffect(() => {
    APIJobs.getAllJobs(accessToken)
      .then((r) => {
        const { data } = r;
        setJobs(data);
      })
      .catch((e) => {
        if (e && e.message && e.message === 'LOGOUT') {
          signOut(userDispatch, history);
        }
      });
    APIBrands.getAllBrands(accessToken)
      .then((r) => {
        const { data } = r;
        setBrandData(data);
      })
      .catch((e) => {
        if (e && e.message && e.message === 'LOGOUT') {
          signOut(userDispatch, history);
        }
      });
  }, [update]);
  const handleUpdateData = () => () => {
    setUpdate(!update);
  };

  return (
    <Grid container className={classes.container}>
      <Grid justifyContent="space-between" item container alignItems="center" className={classes.scraperForm}>
        <Grid item xs={3}>
          <Autocomplete
            id="autocomplete"
            options={brandData}
            key={resetAutoComplete}
            freeSolo
            getOptionLabel={(option) => option.name}
            style={{ width: '100%' }}
            value={brandValue.value}
            onChange={(event, newValue) => {
              handleOnChangeBranName(newValue);
            }}
            renderInput={(params) => (
              <TextField
                onChange={(e) => {
                  setBrandValue(e.target.value);
                }}
                ref={params.InputProps.ref}
                {...params}
                label="Brand Name"
                variant="outlined"
                error={error}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            variant="outlined"
            value={urlValue}
            label="Website Url"
            placeholder="Website Url"
            fullWidth
            onChange={setUrlValue}
            error={error}
          />
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={handleStartScraper()}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress color="secondary" size="24px" /> : 'Start'}
          </Button>
        </Grid>
        <Fade in={error}>
          <Typography color="primary" className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        </Fade>
        <Fade in={(jobAlreadyScraped)}>
          <Grid item xs={12} align="center" className={classes.warningMessageContainer}>
            { brandValue && (
            <Typography color="primary" className={classes.warningMessage}>
              {jobAlreadyScraped}
              {' '}
              has already been processed on the
              {' '}
              {moment(dateScraped).format('DD MMM \'YY')}
            </Typography>
            ) }
          </Grid>
        </Fade>
      </Grid>

      <Grid justifyContent="space-between" item container alignItems="flex-end">
        <Grid container item xs={12} style={{ minHeight: '66vh' }}>
          <TableJobStatusRefactor
            data={jobs}
            update={handleUpdateData}
            removeThisJob={(handleRemoveJob)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
