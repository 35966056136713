import React from 'react';
import { PropTypes } from 'prop-types';
import { TextField } from '@material-ui/core';
// styles
import useStyles from './styles';

export default function TextInput({
  value, onChange, fullWidth, variant, label, error,
}) {
  const classes = useStyles();

  return (
    <TextField
      value={value}
      onChange={(e) => onChange(e.target.value)}
      margin="normal"
      type="text"
      color="primary"
      fullWidth={fullWidth}
      variant={variant}
      label={label}
      error={error}
      InputLabelProps={
        {
          className: classes.input,
        }
      }
    />
  );
}
TextInput.defaultProps = {
  variant: 'standard',
  label: '',
};

TextInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool.isRequired,
};
